import { defineStore } from 'pinia'

export const authentication = defineStore('authentication', () => {
  const accessToken = useStorage<string | undefined>('accessToken', undefined)
  const isLastRouteAccessValid = ref<boolean>(true)

  function setAccessToken(token: string) {
    accessToken.value = token
  }

  function setLastRouteAccessValid(value: boolean) {
    isLastRouteAccessValid.value = value
  }

  function clearAccessToken() {
    accessToken.value = undefined
  }

  return { accessToken, setAccessToken, clearAccessToken, setLastRouteAccessValid, isLastRouteAccessValid }
})
