<script setup lang="ts">
import type { ErrorResponseParamDto } from '@peter-park/user'
import type {
  AdditionalColumnsErrorSchema,
  ContainsSpecialCharactersErrorSchema,
  DuplicateValuesErrorSchema,
  InvalidFileErrorSchema,
  InvalidHeadersErrorSchema,
  MaxColumnLengthErrorSchema,
  MinColumnLengthErrorSchema,
  MissingRequiredValuesErrorSchema,
  RowLimitExceededErrorSchema,
} from '@peter-park/structured-errors'

defineOptions({
  name: 'AccountVerificationFileUploadError',
})

const props = defineProps<{
  error: ErrorResponseParamDto
}>()

const { t } = useI18n()

const errorTitle = computed(() => {
  return t(`uploadFileErrors.${props.error.key}.title`, t('uploadFileErrors.unknown_error.title'))
})

const errorDetails = computed(() => {
  // Every single case of error need specific handling because meta is different
  switch (props.error.key) {
    case 'additional_column': {
      const meta = props.error.meta as AdditionalColumnsErrorSchema
      return t('uploadFileErrors.additional_column.details', { columns: meta.columns.join(', ') })
    }
    case 'exceeds_row_limit': {
      const meta = props.error.meta as RowLimitExceededErrorSchema
      return t('uploadFileErrors.exceeds_row_limit.details', { limit: meta.limit })
    }
    case 'invalid_headers': {
      const meta = props.error.meta as InvalidHeadersErrorSchema
      return t('uploadFileErrors.invalid_headers.details', { headers: meta.columns.join(', ') })
    }
    case 'missing_required_value': {
      const meta = props.error.meta as MissingRequiredValuesErrorSchema
      return t('uploadFileErrors.missing_required_value.details', { row: meta.row, columns: meta.columns.join(', ') })
    }
    case 'duplicate_code': {
      const meta = props.error.meta as DuplicateValuesErrorSchema
      return t('uploadFileErrors.duplicate_code.details', { code: meta.values.join(', '), rows: meta.rows.join(', '), column: meta.columns.join(', ') })
    }
    case 'duplicate_code_different_description': {
      const meta = props.error.meta as DuplicateValuesErrorSchema
      return t('uploadFileErrors.duplicate_code_different_description.details', { existing_description: meta.values[0], new_description: meta.values[1], rows: meta.rows.join(', '), column: meta.columns.join(', ') })
    }
    case 'contains_special_characters': {
      const meta = props.error.meta as ContainsSpecialCharactersErrorSchema
      return t('uploadFileErrors.contains_special_characters.details', { column: meta.column, row: meta.row })
    }
    case 'min_length': {
      const meta = props.error.meta as MinColumnLengthErrorSchema
      return t('uploadFileErrors.min_length.details', { column: meta.column, row: meta.row, length: meta.length, minLength: meta.minLength })
    }
    case 'max_length': {
      const meta = props.error.meta as MaxColumnLengthErrorSchema
      return t('uploadFileErrors.max_length.details', { column: meta.column, row: meta.row, length: meta.length, maxLength: meta.maxLength })
    }
    case 'invalid_file': {
      const meta = props.error.meta as InvalidFileErrorSchema
      return t('uploadFileErrors.invalid_file.details', { code: meta.code, message: meta.message })
    }
    case 'no_rows': {
      return t('uploadFileErrors.no_rows.details')
    }
  }
  return t('uploadFileErrors.unknown_error.details', { meta: props.error.meta })
})

const open = ref(false)
</script>

<template>
  <CollapsibleRoot v-model:open="open" as="div" class="b-1 b-gray-200 rounded-xl px-4 py-4">
    <CollapsibleTrigger
      class="w-full flex justify-between overflow-auto overflow-hidden bg-transparent"
      data-testid="error-collapse-button"
    >
      <span class="text-left">
        {{ errorTitle }}
      </span>
      <div class="i-lucide:chevron-down ml-auto h-5 w-5 bg-gray-500 transition" :class="{ 'rotate--180': open }" />
    </CollapsibleTrigger>
    <CollapsibleContent as="div" class="CollapsibleContent">
      <span class="text-sm text-gray-600 font-400" v-html="errorDetails" />
    </CollapsibleContent>
  </CollapsibleRoot>
</template>
