import { z } from 'zod'
import { fileValidation } from './utils'

export const maxAttachmentSize = 10e6// 10e6 bytes = 10MB
export const acceptedMimeTypes = ['text/csv', '.csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']

export const accountVerificationWizardSchema = z.object({
  stepOne: z.object({
    areaId: z.string().min(2),
  }),
  stepTwo: z.object({
    groupIds: z.array(z.string().min(2)).min(1),
  }),
  stepThree: z.object({
    inputFile: fileValidation(acceptedMimeTypes, maxAttachmentSize, true),
  }),
})
