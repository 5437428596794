<script lang="ts" setup>
// This component is optimized only for Desktop view, we are not supporting mobile view.
import { DialogDescription, DialogTitle, ListboxContent, ListboxFilter, ListboxRoot, VisuallyHidden } from 'radix-vue'
import { useAreaSelector } from '../useAreaSelector'
import AreaGroup from './AreaGroup.vue'
import type { AreaFromGetAreas } from '@/types'

defineOptions({
  name: 'AppHeaderAreaSelectorModal',
})

const props = withDefaults(defineProps<{
  /**
   * The areas to display in the selector
   */
  areas: AreaFromGetAreas[]
  /**
   * Whether to allow selecting multiple areas; will switch between a select and multi-select component
   * @default true (multi-select)
   */
  multiple?: boolean
  /**
   * Where to display the modal (element bind)
   */
  to?: string

}>(), {
  multiple: true,
  to: 'body',
})

const open = defineModel('open', {
  default: false,
})

const { t } = useI18n()

const { areas, multiple } = toRefs(props)
const {
  _selectedAreas,
  areAllAreaSelected,
  handleSelectAll,
  isAreaSelected,
  isAtLeastOneAreaSelected,
  searchTerm,
  selectedAreasArray,
  selectedFilteredAreasArray,
  setSelectedAreas,
  storeSetSelectedAreas,
  unselectedFilteredAreasArray,
  updateSelectedAreas,
} = useAreaSelector(areas, multiple, false)

watch(open, (newOpen) => {
  if (!newOpen) {
    searchTerm.value = ''
    updateSelectedAreas(storeSetSelectedAreas)
  }
})

const listboxWrapper = ref<HTMLElement | null>(null)

function enterPressHandler() {
  // radix internally handles the enter key press event as a whole in the listbox
  // meaning that the filter input will fire an enter event even when we are actually pressing enter on a listbox item
  // because we don't want to trigger the select/deselect all functionality when pressing enter on a listbox item
  // we need to check if there is a highlighted item in the listbox and if there is, we don't want to do anything
  const highlighted = listboxWrapper?.value?.querySelector('[data-highlighted]')
  if (highlighted) {
    return
  }
  toggleAllFiltered()
}

function toggleAllFiltered() {
  if (searchTerm.value) {
    const currentAreas = selectedAreasArray.value || []
    const selectedAreas = (unselectedFilteredAreasArray.value.length)
      ? [...currentAreas, ...unselectedFilteredAreasArray.value] // if there are still selectable items, select all
      : currentAreas.filter(area => !isAreaSelected(area)) // if all items are selected, deselect them instead
    setSelectedAreas(selectedAreas)
  }
  else {
    // when there is no search term, we want to clear the selection
    setSelectedAreas([])
  }
}

const selectAllIconClass = computed(() => {
  if (areAllAreaSelected.value) {
    return 'i-tabler:square-rounded-check-filled'
  }
  if (isAtLeastOneAreaSelected.value) {
    return 'i-tabler:square-rounded-minus-filled'
  }
  return 'i-tabler:square-rounded'
})

const selectAllLabel = computed(() => {
  if (isAtLeastOneAreaSelected.value) {
    return t('deselect_all')
  }
  return t('select_all')
})
</script>

<template>
  <div>
    <DialogRoot :open="open" @update:open="(value) => open = value">
      <DialogPortal>
        <DialogOverlay class="fixed inset-0 z-30 bg-gray-700 opacity-50" />
        <DialogContent
          dialog-title="BaseDialog"
          class="fixed left-1/2 top-1/2 z-100 w-150 flex flex-col translate-x-[-50%] translate-y-[-50%] justify-between rounded-2xl bg-gray-25 p-2.5 focus:outline-none"
        >
          <VisuallyHidden>
            <DialogTitle>{{ t('account_verification.steps.select_area') }}</DialogTitle>
            <DialogDescription>{{ t('account_verification.steps.select_area') }}</DialogDescription>
          </VisuallyHidden>
          <ListboxRoot v-model="_selectedAreas" :multiple="multiple" :as-child="true">
            <div ref="listboxWrapper" class="h-full flex flex-col items-center justify-between rounded-md bg-white ring-1 ring-gray-300">
              <div class="w-full flex items-center gap-2 border-b-1 border-gray-300 px-3 py-2">
                <div class="i-lucide:search h-4 w-4 text-gray-500" />
                <ListboxFilter
                  v-model="searchTerm"
                  :auto-focus="true"
                  class="w-full px-2 text-base focus:rounded focus:outline-none focus:ring-1.5 focus:ring-ppGreenNeon placeholder-fw-300 placeholder-gray-500"
                  :placeholder="t('search')"
                  @keydown.enter.self="multiple && enterPressHandler()"
                />
              </div>
              <div v-if="props.multiple" class="w-full flex items-center justify-start border-b-1 border-t-gray-300">
                <button class="flex items-center gap-2 bg-transparent px-3 py-2" @click="handleSelectAll()">
                  <div :class="selectAllIconClass" class="text-ppGreenDeep" />
                  <p class="text-sm text-gray-500">
                    {{ selectAllLabel }}
                  </p>
                </button>
              </div>
              <ListboxContent class="w-full flex flex-col gap-2 pt-3">
                <AreaGroup
                  :label="multiple ? t('selected_area', selectedFilteredAreasArray.length) : t('area', selectedFilteredAreasArray.length + unselectedFilteredAreasArray.length)"
                  :selected-areas="selectedFilteredAreasArray"
                  :unselected-areas="unselectedFilteredAreasArray"
                  :multiple="multiple"
                />
              </ListboxContent>
            </div>
          </ListboxRoot>

          <div class="mt-2 w-full flex justify-end px-2 pb-1">
            <button
              class="border-1 border-gray-300 rounded bg-white px-1 py-0.5 text-xs text-gray-500 leading-none"
              @click="open = false"
            >
              <p class="text-xs text-gray-500">
                {{ t('close') }}
              </p>
            </button>
          </div>
        </DialogContent>
      </DialogPortal>
    </DialogRoot>
  </div>
</template>
