<script setup lang="ts">
import MarkdownIt from 'markdown-it'
import { logAreaServiceNoteClosed, logAreaServiceNoteOpened } from '@/utils/logger'

const props = defineProps<{
  note: string
}>()

const { t } = useI18n()
const isDialogOpen = ref(false)

function open() {
  isDialogOpen.value = true
  logAreaServiceNoteOpened()
}
function close() {
  isDialogOpen.value = false
  logAreaServiceNoteClosed()
}
const markdown = new MarkdownIt()
const formattedNote = computed(() => {
  return markdown.render(props.note)
})
</script>

<template>
  <button class="i-lucide:info absolute left--7 my-auto h-full w-5 bg-white" @click="open" />
  <Dialog :open="isDialogOpen" wide @click-outside="isDialogOpen = false">
    <template #headline>
      {{ t('note') }}
    </template>
    <template #content>
      <div class="text-left prose" v-html="formattedNote" />
    </template>
    <template #buttons>
      <BaseButton color="outline-gray" @click="close()">
        {{ t('close') }}
      </BaseButton>
    </template>
  </Dialog>
</template>
