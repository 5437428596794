import type { Ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useAreaSelection } from './useAreaSelection'
import type { AreaFromGetAreas } from '@/types'
import { userSettings } from '@/stores/userSettings'

export function useAreaSelector(areas: Ref<AreaFromGetAreas[]>, multiple: Ref<boolean>, syncWithStore = false) {
  const userSettingsStore = userSettings()
  const { selectedAreas } = storeToRefs(userSettingsStore)

  const _selectedAreas = ref<AreaFromGetAreas[] | AreaFromGetAreas | undefined>(selectedAreas.value)

  function setSelectedAreas(areas: AreaFromGetAreas[] | AreaFromGetAreas | undefined) {
    _selectedAreas.value = areas
  }

  const {
    areAllAreaSelected,
    filteredAreas,
    initializeSelectedAreas,
    isAreaSelected,
    isAtLeastOneAreaSelected,
    searchTerm,
    selectAllChange,
    selectedAreaObject,
    selectedAreasArray,
    selectedFilteredAreasArray,
    unselectArea,
    unselectedFilteredAreasArray,
  } = useAreaSelection(
    multiple,
    areas,
    _selectedAreas,
    setSelectedAreas,
  )

  function updateSelectedAreas(callback: (areas: AreaFromGetAreas[] | AreaFromGetAreas | undefined) => void) {
    const areas = multiple.value ? selectedAreasArray.value : selectedAreaObject.value
    callback(areas)
  }

  function handleSelectAll() {
    if (isAtLeastOneAreaSelected.value) {
      selectAllChange(false, true)
    }
    else {
      selectAllChange(true)
    }
  }

  onMounted(() => {
    initializeSelectedAreas()
  })

  watch(multiple, () => {
    updateSelectedAreas(setSelectedAreas)
  }, {
    immediate: true,
  })

  // when the store changes, we update the local state. This will not trigger if the values are the same
  watch(selectedAreas, () => {
    _selectedAreas.value = selectedAreas.value
  })

  // when the local state changes, we update the store if syncWithStore is true
  if (syncWithStore) {
    watch(_selectedAreas, () => {
      userSettingsStore.setSelectedAreas(_selectedAreas.value)
    })
  }
  return {
    _selectedAreas,
    areAllAreaSelected,
    filteredAreas,
    handleSelectAll,
    isAreaSelected,
    isAtLeastOneAreaSelected,
    searchTerm,
    selectedAreaObject,
    selectedAreas,
    selectedAreasArray,
    selectedFilteredAreasArray,
    setSelectedAreas,
    storeSetSelectedAreas: userSettingsStore.setSelectedAreas,
    unselectArea,
    unselectedFilteredAreasArray,
    updateSelectedAreas,
  }
}
