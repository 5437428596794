import { type $Fetch, ofetch } from 'ofetch'
import { auth0 } from './auth0'
import type {
  AreasResponse,
} from '@/types'

export const urls = {
  getAreas: '/chair/info',
}

export const client: $Fetch = ofetch.create({
  async onRequest({ request, options }) {
    if (import.meta.env.DEV) {
      // eslint-disable-next-line no-console
      console.info('[fetch request]', request, options)
    }
    const token = await auth0.getAccessTokenSilently()
    options.headers = new Headers({
      Authorization: `Bearer ${token}`,
    })
    options.baseURL = import.meta.env.VITE_FALCON_API_BASE_URL
  },
})

export async function getAreas(): Promise<AreasResponse> {
  return await client(urls.getAreas, { method: 'GET' })
}
