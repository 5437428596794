import { useQuery, useQueryClient } from '@tanstack/vue-query'
import type { ComputedRef, MaybeRef } from 'vue'
import { useOpenApiClient } from './useAreaTreeOpenApiClient'

export function useGetAllAreas(enabled: MaybeRef<boolean> | ComputedRef<boolean> = true) {
  const queryClient = useQueryClient()
  const { apiInstance } = useOpenApiClient()
  const {
    isLoading,
    isFetching,
    isError,
    isSuccess,
    data,
    error,
    refetch,
    suspense,
  } = useQuery({
    queryKey: ['getAllAreas'],
    queryFn: () => apiInstance.value.areaUserControllerFindUserAreas(),
    enabled,
  })

  watch(data, (areas) => {
    areas?.forEach((area) => {
      queryClient.setQueryData(['getArea', area.id], area)
    })
  })

  const areas = computed(() => {
    return data.value ?? []
  })

  const areasNameAndUUID = computed(() => {
    return areas.value.map((area) => {
      const locality = area.address ? area.address.city : ''
      return {
        value: area.id,
        name: [locality, area.name]
          .filter(v => v !== '')
          .join(', '),
      }
    })
  })

  return {
    isLoading,
    isFetching,
    isError,
    isSuccess,
    data,
    error,
    refetch,
    suspense,
    areas,
    areasNameAndUUID,
    suspenseGetAreas: suspense,
  }
}
