import { useQueryClient } from '@tanstack/vue-query'
import type { User } from '@auth0/auth0-vue'
import { useAuth0 } from '@auth0/auth0-vue'
import type { Ref } from 'vue'
import { userSettings } from '@/stores/userSettings'
import { authentication } from '@/stores/authentication'

export function useAuth(): {
  logout: () => Promise<void>
  checkSession: () => Promise<void>
  isAuthenticated: Ref<boolean>
  user: Ref<User | undefined>
  getAccessTokenSilently: () => Promise<string>
  loginWithRedirect: () => void
} {
  const userSettingsStore = userSettings()
  const authenticationStore = authentication()
  const queryClient = useQueryClient()
  const { logout: _logout, checkSession, isAuthenticated, user, getAccessTokenSilently, loginWithRedirect } = useAuth0()

  async function logout() {
    // get the origin for the logout URI
    const redirectLogoutUri = window.location.origin

    // clear the query client cache
    queryClient.clear()

    // remove all queries from cache
    localStorage.removeItem('tanstack-query-["getAreas"]')

    // clear the access token
    authenticationStore.clearAccessToken()

    // clear the selected areas
    userSettingsStore.setSelectedAreas([])
    await _logout({ logoutParams: { returnTo: redirectLogoutUri } })
  }

  return {
    logout,
    checkSession,
    getAccessTokenSilently,
    isAuthenticated,
    user,
    loginWithRedirect,
  }
}
