<script lang="ts" setup>
import { Toaster } from 'vue-sonner'
import { useAuth0 } from '@auth0/auth0-vue'
import { VueQueryDevtools } from '@tanstack/vue-query-devtools'
import { storeToRefs } from 'pinia'
import { authentication } from './stores/authentication'
import { logAuthenticated } from '@/utils'
import { userSettings } from '@/stores/userSettings'
import { useGetAreas } from '@/composables/useGetAreas'

const route = useRoute()
const layout = computed(() => {
  return route.meta.layout || 'SidebarLayout'
})
const { user, isLoading } = useAuth0()

const userSettingsStore = userSettings()
const { accessToken } = storeToRefs(authentication())

// watch for changes in the access token
// in case the accessToken changes, we need to refresh the page
// so the user cannot login with multiple accounts on the same browser
// in case of a logout, the accessToken will be undefined, so the page will refresh
// this is done here and not in the composable so we have only one watcher instance
watch(accessToken, (newAccessToken) => {
  if (!newAccessToken || accessToken.value !== newAccessToken)
    window.location.reload()
})

const { locale } = useI18n()

// if the userSelectedLanguage is present, set the i18n locale to that value
if (userSettingsStore.userSelectedLanguage)
  locale.value = userSettingsStore.userSelectedLanguage

else
  userSettingsStore.setLanguage(navigator.language)

watch(user, () => {
  if (!isLoading.value)
    logAuthenticated(route.query, user?.value?.name ?? '')
})

// always fetch areas on app load
useGetAreas(true)
</script>

<template>
  <component :is="layout">
    <router-view :key="route.path" />
  </component>
  <div v-if="isLoading || !user" class="absolute top-0 z-50 h-100vh w-100vw content-center backdrop-blur-sm">
    <div class="i-svg-spinners:180-ring-with-bg m-auto h-24 w-24 bg-pp-green" />
  </div>
  <Toaster
    close-button
    :duration="10 * 1000"
    rich-colors
  />
  <VueQueryDevtools />
</template>
