<script setup lang="ts">
import { useField } from 'vee-validate'
import { useFuse } from '@vueuse/integrations/useFuse'
import DropdownItem from './Item.vue'
import type { DropdownProps, Option } from './types'

defineOptions({
  name: 'FormDropdown',
})
const props = withDefaults(defineProps<DropdownProps>(), {
  inputHint: '',
  loading: false,
})
const { t } = useI18n()

const { name, options } = toRefs(props)

const {
  value: inputValue,
  errorMessage,
  meta,
} = useField(name, undefined, { validateOnMount: true })

const searchTerm = ref('')
const isOpen = ref(false)

const selectedValue = ref<Option>()

watch(selectedValue, () => {
  if (selectedValue.value) {
    inputValue.value = selectedValue.value.value
  }
})

const { results } = useFuse(
  searchTerm,
  options,
  {
    fuseOptions: {
      keys: ['name', 'value'],
      threshold: 0.3,
    },
    matchAllWhenSearchEmpty: true,
  },
)

const filteredOptions = computed<Option[]>(() => {
  return results.value.map(result => result.item)
})

function reset() {
  selectedValue.value = undefined
  inputValue.value = ''
}
</script>

<template>
  <slot />
  <div v-if="loading" class="relative h-10.5 w-full flex animate-pulse content-center justify-end b-1 rounded rounded-lg bg-gray-200">
    <div
      class="i-tabler-loader-2 my-auto mr-3 h-5 w-5 animate-spin bg-gray-300 duration-700 ease-in-out"
    />
  </div>
  <ComboboxRoot
    v-else
    v-model="selectedValue"
    v-model:search-term="searchTerm"
    v-model:open="isOpen"
    :filter-function="() => filteredOptions"
    class="relative w-full"
  >
    <ComboboxAnchor
      :class="{ rounded: !isOpen }"
      class="w-full content-center b-1 rounded rounded-lg bg-white px-3.5 py-2.5 text-sm"
    >
      <div class="h-full w-full flex justify-between gap-1">
        <div v-if="selectedValue" class="w-full flex items-center justify-between gap-2 bg-transparent">
          <ComboboxTrigger class="flex flex-1 items-center gap-2 bg-transparent">
            <div v-if="selectedIcon" :class="selectedIcon" class="h-5 min-w-5 text-gray-primary" />
            <span class="h-5 max-w-full overflow-hidden text-ellipsis whitespace-nowrap text-left text-3.5 text-gray-700">{{ selectedValue?.name }}</span>
          </ComboboxTrigger>
          <button type="button" class="i-radix-icons:cross-2 h-4 min-w-4 flex-shrink-0" @click="reset" />
        </div>
        <ComboboxTrigger v-else class="w-full flex items-center justify-between gap-2 bg-transparent">
          <div class="i-custom:search h-5 min-w-5 text-gray-primary" />
          <ComboboxInput class="h-full w-full bg-white text-base outline-none" :placeholder="inputHint || t('search')" />
        </ComboboxTrigger>
      </div>
    </ComboboxAnchor>
    <ComboboxContent
      class="absolute z-30 mt-2 max-h-80 w-full b-1 rounded-lg bg-white md:max-h-100"
    >
      <VirtualList :items="filteredOptions" :item-height="40">
        <template #default="{ data }">
          <DropdownItem :option="data" />
        </template>
      </VirtualList>
    </ComboboxContent>
  </ComboboxRoot>
  <div v-if="errorMessage && meta.dirty" class="px-1 text-red-500">
    <span class="text-sm">{{ errorMessage }}</span>
  </div>
</template>
