<script lang="ts" setup generic="DataType">
import { toValue } from 'vue'
import type { ColumnDef } from '@tanstack/vue-table'
import { getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useVueTable } from '@tanstack/vue-table'
import { useRowSelection } from '@peter-park/vue-table-utils'

defineOptions({
  name: 'BaseTable',
})

const props = withDefaults(
  defineProps<{
    data: DataType[]
    columns: ColumnDef<DataType, any>[]
    showActions?: boolean
    cursorPagination?: {
      enabled: boolean
      hasNextPage: boolean
      hasPreviousPage: boolean
    }
    loading: boolean
    multiSelect?: boolean
    pagination?: boolean
    totalCount?: number
    pageSize?: number
    showSearch?: boolean
    sortable?: boolean
    id?: string
    title?: string
    badges: { label: string, color?: string, loading?: boolean }[]
  }>(),
  {
    showSearch: true,
    pageSize: 10,
    showActions: true,
    multiSelect: false,
    cursorPagination: () => ({
      enabled: false,
      hasNextPage: false,
      hasPreviousPage: false,
    }),
    id: 'table',
  },
)

const emit = defineEmits<{
  onPageChange: [nextPage: number]
  loadNext: []
  loadPrevious: []
  onFilterChange: [searchQuery: string]
  tableRowSelection: []
}>()

const selectedRowIndexes = defineModel<number[]>('selectedRowIndexes')

const { t } = useI18n()

const { data: tableData, totalCount } = toRefs(props)

const globalFilter = ref('')

const pagination = ref({ pageIndex: 0, pageSize: props.pageSize })

const { rowSelection, rowSelectionItems, changeRowSelection } = useRowSelection()

const table = useVueTable({
  get data() {
    return tableData.value
  },
  autoResetPageIndex: props.cursorPagination.enabled ? false : undefined,
  columns: props.columns,
  manualPagination: props.pagination && !props.cursorPagination.enabled,
  rowCount: props.pagination ? props.totalCount : undefined,
  initialState: {
    pagination: {
      pageSize: props.pageSize,
    },
  },
  state: {
    // biome-ignore lint/suspicious/useGetterReturn: <explanation>
    get pagination() {
      if (props.pagination || props.cursorPagination.enabled)
        return pagination.value
    },
    get rowSelection() {
      return toValue(rowSelection)
    },
  },
  onPaginationChange: (updater) => {
    if (props.pagination || props.cursorPagination.enabled) {
      pagination.value = updater instanceof Function
        ? updater(pagination.value)
        : updater
    }
  },
  getCoreRowModel: getCoreRowModel(),
  getSortedRowModel: getSortedRowModel(),
  getFilteredRowModel: getFilteredRowModel(),
  getPaginationRowModel: (props.cursorPagination.enabled) ? getPaginationRowModel() : undefined,
  onRowSelectionChange: props.multiSelect
    ? (updater) => {
        changeRowSelection(updater)
        selectedRowIndexes.value = rowSelectionItems.value
        emit('tableRowSelection')
      }
    : undefined,
})

if (props.pagination && !props.cursorPagination.enabled) {
  const currentPageIndex = computed(() => table.getState().pagination.pageIndex)
  watch(currentPageIndex, () => {
    emit('onPageChange', currentPageIndex.value)
  })
}

function onFilterChange() {
  pagination.value.pageIndex = 0
  table.resetRowSelection()
  emit('onFilterChange', globalFilter.value)
}

watch(totalCount, (newTotal, oldTotal) => {
  if (oldTotal && (!newTotal || newTotal < oldTotal)) {
    pagination.value.pageIndex = 0
    table.resetRowSelection()
  }
})

// this can be moved to vue-table-utils
function toggleSelectAll(value: boolean) {
  if (!value) {
    table.resetRowSelection()
  }
  else {
    table.toggleAllRowsSelected()
  }
}
</script>

<template>
  <div class="rounded-2xl bg-gray-100 px-1 py-2">
    <div v-if="props.title" class="flex items-center gap-2 px-4 pt-4">
      <span class="text-4.5 text-gray-900 font-600 leading-7">
        {{ props.title }}
      </span>
      <div
        v-for="badge in badges" :key="badge.label"
        class="rounded-4 bg-gray-200 px-2 py-0.5 text-3 text-gray-500 font-500"
        :class="{ 'animate-pulse text-gray-400 w-20 h-5': badge.loading }"
      >
        {{ badge.label }}
      </div>
    </div>
    <div class="flex flex-col lg:flex-row lg:items-center lg:justify-between">
      <div class="w-full items-center px-4 py-1 sm:w-100 lg:py-3">
        <BaseSearchInput v-if="props.showSearch" :id="id" v-model="globalFilter" @update:model-value="onFilterChange()" />
      </div>
      <div class="px-4 py-1 lg:py-3">
        <slot name="additionalHeaderActions" />
      </div>
    </div>
    <div class="overflow-x-auto px-1">
      <div class="inline-block min-w-full pb-0.5 pt-2 align-middle">
        <div class="overflow-hidden rounded-2 ring-1 ring-gray-200">
          <table :id="id" class="relative min-w-full divide-y divide-gray-200">
            <BaseTableHead
              :multi-select="{ enable: props.multiSelect, isAllSelected: table.getIsAllRowsSelected(), isSomeSelected: table.getIsSomeRowsSelected() }"
              :header-instance="table.getFlatHeaders()"
              :show-actions="props.showActions"
              @on-toggle-select-all="toggleSelectAll"
            >
              <template #additionalColumns>
                <slot name="additionalHeaders" />
              </template>
            </BaseTableHead>
            <span v-if="loading && data.length > 0" class="absolute bottom-0 z-1 h-[calc(100%-44px)] w-full flex items-center justify-center !b-unset" :class="data.length === 0 ? 'relative h-10 justify-center' : ''">
              <div
                class="i-tabler-loader-2 my-auto mr-3 h-10 w-10 animate-spin bg-gray-300 duration-700 ease-in-out"
              />
            </span>
            <BaseTableBody :rows="table.getRowModel().rows" :class="loading ? 'blur-sm' : ''" :selectable="multiSelect">
              <template #additionalColumns="{ row }">
                <slot name="additionalColumns" :row />
              </template>
              <template #actions="{ row }">
                <BaseTableCell v-if="props.showActions" class="!p-2">
                  <div class="flex flex-col items-start gap-2 md:flex-row md:items-center">
                    <slot name="actions" :row />
                  </div>
                </BaseTableCell>
              </template>
            </BaseTableBody>
          </table>
          <span v-if="loading && data.length === 0" class="min-h-10 flex justify-center py-2">
            <div
              class="i-tabler-loader-2 my-auto mr-3 h-10 w-10 animate-spin bg-gray-300 duration-700 ease-in-out"
            />
          </span>
          <span v-if="!loading && data.length === 0" class="mx-auto h-10 flex justify-center py-2 text-sm text-gray-500 font-500">{{ t('no_data') }}</span>
        </div>
      </div>
    </div>
    <BaseTablePagination
      v-if="props.pagination || props.cursorPagination.enabled"
      :table
      :has-next-page="props.cursorPagination.hasNextPage"
      :has-previous-page="false"
      :current-page="pagination.pageIndex + 1"
      @load-next="emit('loadNext')"
      @load-previous="emit('loadPrevious')"
    />
  </div>
</template>
