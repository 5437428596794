<script lang="ts" setup>
import type { NavigationSection } from '@/types'
import { logClickLogout } from '@/utils/logger'
import { useAuth } from '@/composables/useAuth'

defineOptions({
  name: 'Sidebar',
})

const props = defineProps<{
  navigation: NavigationSection[]
}>()

const { t } = useI18n()
const { user, isAuthenticated, logout } = useAuth()
const route = useRoute()

const appVersion = $APP_VERSION

async function onLogout() {
  logClickLogout(route.query, user?.value?.name ?? '')
  await logout()
}
</script>

<template>
  <div class="h-full flex flex-col justify-between overflow-scroll border-r-1 bg-gray-100">
    <div class="flex grow flex-col gap-y-5 overflow-y-auto p-4 pb-2">
      <nav class="flex flex-1 flex-col gap-y-5">
        <ul
          v-for="(section, sectionIndex) in props.navigation" :key="sectionIndex" role="list" class="flex flex-col gap-y-2"
          :class="{ hidden: !section.items.find(subItem => subItem.permitted) }"
        >
          <span v-if="section.items.find(subItem => subItem.permitted)" class="text-sm text-gray-400 font-semibold leading-5">{{ section.name }}</span>
          <li>
            <ul role="list" class="flex flex-col gap-1">
              <li
                v-for="(item, itemIndex) in section.items" :key="itemIndex"
                :class="[
                  !item.permitted && 'hidden',
                  route.name === item.locationName ? 'bg-green-500 rounded-md' : 'text-gray-primary hover:text-gray-800',
                ]"
              >
                <AppSidebarLinkGroup v-if="item.children?.find(subItem => subItem.permitted) && item.permitted" :navigation="item" />
                <template v-else-if="item.permitted">
                  <AppSidebarLink :name="item.name" :location-name="item.locationName ?? ''" :icon="item.icon" />
                </template>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
    <div class="flex flex-col gap-y-3 p-4 pt-3">
      <AppSidebarLanguageSelector />
      <div v-if="isAuthenticated && user" class="flex justify-between b-t-1 pb-2 pl-2 pt-4">
        <div class="flex items-center gap-2 pt-2">
          <img class="inline-block h-10 w-10 rounded-full" :src="user.picture" alt="User Avatar">
          <div class="flex flex-col leading-5" aria-hidden="true">
            <span class="text-3.5 text-gray-500 font-semibold">{{ user.name }}</span>
            <span class="text-3 text-gray-500 font-light">{{ user.email }}</span>
          </div>
        </div>
        <button class="w-fit flex items-center gap-2 rounded bg-gray-100 p-1 text-gray-primary transition hover:text-gray-800" @click="onLogout()">
          <div class="i-lucide:log-out h-5 w-5 shrink-0" />
        </button>
      </div>
      <span class="pl-2 text-2.5 text-gray-300 leading-0">
        {{ t('version') }} <b>{{ appVersion }}</b>
      </span>
    </div>
  </div>
</template>
