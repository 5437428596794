<script lang="ts" setup>
import { ListboxGroup, ListboxItem, ListboxVirtualizer } from 'radix-vue'
import { getAreaLabel } from '../utils'
import type { AreaFromGetAreas } from '@/types'

const props = withDefaults(
  defineProps<{
    multiple: boolean
    label: string
    itemSize?: number
    maxItems?: number
    minItems?: number
    selectedAreas: Array<AreaFromGetAreas>
    unselectedAreas: Array<AreaFromGetAreas>
  }>(),
  {
    minItems: 10,
    maxItems: 10,
    itemSize: 32,
  },
)

const minListHeight = computed(() => `${props.itemSize * props.minItems}px`)
const maxListHeight = computed(() => `${props.itemSize * props.maxItems}px`)

const indicatorSelectedClass = computed(() => {
  return props.multiple ? 'i-tabler:square-rounded-check-filled text-ppGreenDeep' : 'i-tabler:circle-check-filled text-ppGreenDeep'
})

const indicatorUnselectedClass = computed(() => {
  return props.multiple ? 'i-tabler:square-rounded text-gray-300' : 'i-tabler-circle text-gray-300'
})

function calcIndicatorClass(option: AreaFromGetAreas) {
  return props.selectedAreas.includes(option) ? indicatorSelectedClass.value : indicatorUnselectedClass.value
}

function isOptionDisabled(option: AreaFromGetAreas) {
  return !props.multiple && props.selectedAreas.includes(option)
}

const areas = computed(() => {
  return props.selectedAreas.concat(props.unselectedAreas)
})
</script>

<template>
  <ListboxGroup class="h-full">
    <ListboxGroupLabel class="mb-2 ml-3 flex items-center gap-2 text-xs text-gray-400 font-300 uppercase">
      <p>{{ props.label }}</p>
      <div v-if="props.multiple" class="flex gap-1">
        <div class="rounded-sm bg-gray-200 px-1 py-0.5 text-3 text-gray-500 font-500 leading-none">
          {{ props.selectedAreas.length }}
        </div>
        /
        <div class="rounded-sm bg-gray-200 px-1 py-0.5 text-3 text-gray-500 font-500 leading-none">
          {{ areas.length }}
        </div>
      </div>
    </ListboxGroupLabel>

    <div
      class="virtual-wrapper overflow-auto scrollbar-thumb-color-grayCool-300 scrollbar-track-color-transparent scrollbar-radius-2 scrollbar-thumb-radius-4 scrollbar-track-radius-4 scrollbar-w-8px scrollbar scrollbar-rounded"
    >
      <ListboxVirtualizer v-slot="{ option }" :estimate-size="props.itemSize" :options="areas" :text-content="(opt) => getAreaLabel(opt)">
        <ListboxItem
          :value="option"
          class="group relative w-full flex cursor-pointer select-none items-center justify-start whitespace-nowrap px-3 outline-none transition-all data-[disabled]:cursor-default"
          :disabled="isOptionDisabled(option)"
        >
          <div class="absolute" :class="calcIndicatorClass(option)" />
          <p class="ml-5 text-gray-900 leading-tight transition-all transition-duration-100 group-data-[disabled]:opacity-50" :class="{ 'group-data-[highlighted]:font-medium': !isOptionDisabled(option) }">
            {{ getAreaLabel(option) }}
          </p>
        </ListboxItem>
      </ListboxVirtualizer>
    </div>
  </ListboxGroup>
</template>

<style scoped>
.virtual-wrapper {
  min-height: v-bind(minListHeight);
  max-height: v-bind(maxListHeight);
}
</style>
